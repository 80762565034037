import { ReactNode, useState, Fragment } from 'react';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';

import {
  Card,
  CardHeader,
  CardBody,
  CustomAction,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Color } from '@shippypro/design-system-web/enums';

export interface ICardViewProps {
  header: string | ReactNode;
  collapsedSummary?: ReactNode;
  children: ReactNode;
  name?: string;
  flex?: boolean;
  col?: boolean;
  muted?: boolean;
  headerMuted?: boolean;
  collapsible?: boolean;
  collapseOnHeader?: boolean;
  initialOpened?: boolean;
  // TODO: if we need to show something else instead of the plain action, instead of keeping
  // the customAction prop think about creating a union type like:
  // customActionComponent: 'button' | 'action-text' | false
  // This way we could discern in the code which action component we want and add new ones
  // without enlarging the props list!
  customAction?: boolean;
  customActionTooltip?: string;
  customActionDisabled?: boolean;
  actionIcon?: string;
  actionLabel?: string;
  iconColor?: Color;
  iconSize?: number;
  actionFn?: () => void;
  cardCSS?: string;
  bodyCSS?: string;
  headerCSS?: string;
  actionCSS?: string;
  hideActions?: boolean;
  className?: string;
  isLoading?: boolean;
  dataTest?: string;
}

// TODO: move to design system + add documentation
const CardView: React.FC<ICardViewProps> = ({
  header,
  collapsedSummary = null,
  headerMuted = true,
  children,
  name,
  muted = false,
  flex = true,
  col = false,
  collapsible = false,
  collapseOnHeader = false,
  initialOpened = null,
  // TODO: if we need to show something else instead of the plain action, instead of keeping
  // the customAction prop think about creating a union type like:
  // customActionComponent: 'button' | 'action-text' | false
  // This way we could discern in the code which action component we want and add new ones
  // without enlarging the props list!
  customAction = false,
  customActionTooltip = '',
  customActionDisabled = false,
  actionIcon,
  actionLabel = '',
  iconSize = 18,
  iconColor,
  actionFn = () => {},
  cardCSS = '',
  bodyCSS = '',
  headerCSS,
  actionCSS,
  hideActions,
  className = '',
  isLoading = false,
  dataTest = '',
}) => {
  const [collapse, setCollapse] = useState<boolean | null>(initialOpened);
  const BodyWrapper = collapsible ? Collapse : Fragment;

  return (
    <div
      id="wrapper"
      className={classnames('flex items-center w-full', className, {
        [`card-${name}`]: name,
      })}
      data-test={dataTest}
    >
      <Card className={classnames('w-full bg-white', cardCSS)}>
        {header !== '' && (
          <CardHeader
            className={classnames(headerCSS, {
              'cursor-pointer': collapseOnHeader,
            })}
            id="wrap-header"
            onClick={() =>
              collapseOnHeader &&
              setCollapse(collapse !== null ? !collapse : false)
            }
          >
            <div className="flex justify-between w-full items-center">
              {typeof header === 'string' ? (
                <div
                  className={classnames({
                    'text-muted': headerMuted,
                    'font-bold': !headerMuted,
                  })}
                >
                  {header}
                </div>
              ) : (
                header
              )}
              {!hideActions && (
                <div className="flex card-actions !gap-2 items-center justify-end shrink-0">
                  <div className="flex card-actions !gap-2 items-center overflow-hidden">
                    {collapse === false && collapsedSummary}
                  </div>
                  <div className="flex card-actions !gap-2 items-center overflow-hidden min-w-[18px]">
                    {customAction && (
                      <CustomAction
                        name={name}
                        customActionTooltip={customActionTooltip}
                        disabled={customActionDisabled}
                        actionCSS={actionCSS}
                        actionFn={actionFn}
                        actionIcon={actionIcon}
                        iconSize={iconSize}
                        iconColor={iconColor}
                        actionLabel={actionLabel}
                        isLoading={isLoading}
                      />
                    )}
                    {collapsible && !isLoading && (
                      <IconHelper
                        icon="IconChevronDown"
                        size={iconSize}
                        className={classnames(
                          'card-collapse-btn cursor-pointer',
                          {
                            'animate-flip-up': collapse === false,
                            'animate-flip-down': collapse,
                          },
                        )}
                        onClick={e => {
                          e.stopPropagation();
                          setCollapse(collapse !== null ? !collapse : false);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </CardHeader>
        )}
        <CardBody
          className={classnames('overflow-auto', bodyCSS, {
            'w-full flex justify-center': flex,
            'flex-col': col,
            'text-muted': muted,
          })}
        >
          <BodyWrapper
            {...(collapsible
              ? { isOpen: collapse === null ? true : collapse }
              : {})}
          >
            {children}
          </BodyWrapper>
        </CardBody>
      </Card>
    </div>
  );
};

CardView.displayName = 'CardView_memoized';

export default CardView;

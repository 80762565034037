import { FC } from 'react';

import { DotWithTitle } from '@ds-web/components/molecules/ui/dots/dot-with-title/DotWithTitle';

import { IChartLegendProps } from '@ds-web/components/molecules/ui/charts/chart-legend/types';

/**
 * Component to render the legend of a chart
 * @param bars
 * @param labels
 * @constructor
 */
export const ChartLegend: FC<IChartLegendProps> = ({ bars, labels }) => {
  return (
    <div className="flex mb-2 gap-1" data-test="graph-legend">
      {bars.map(bar => (
        <DotWithTitle
          key={bar.YAxisKey}
          subtitle={labels[bar.YAxisKey]}
          color={bar.fill ?? '#000'}
          dataTestId="graph-legend-element"
        />
      ))}
    </div>
  );
};

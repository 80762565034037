import {
  IconOption,
  SingleSelectedIconOption,
  AvatarOption,
  SingleSelectedAvatarOption,
  ImageOption,
  MultiSelectedImageOption,
  SingleSelectedImageOption,
} from '@shippypro/design-system-web';

export const getIconComponents = ({
  hasIcons,
  hasAvatars,
  hasImages,
  isMulti,
}: {
  hasIcons?: boolean;
  hasAvatars?: boolean;
  hasImages?: boolean;
  isMulti?: boolean;
}) =>
  hasIcons
    ? {
        Option: IconOption,
        Control: SingleSelectedIconOption,
      }
    : hasAvatars
      ? {
          Option: AvatarOption,
          Control: SingleSelectedAvatarOption,
        }
      : hasImages && isMulti
        ? {
            Option: ImageOption,
            MultiValue: MultiSelectedImageOption,
          }
        : hasImages && !isMulti
          ? {
              Option: ImageOption,
              Control: SingleSelectedImageOption,
            }
          : {};

// ########################## [IMPORTANT LIBRARIES]
import { FC, Fragment, memo } from 'react';
import classnames from 'classnames';

// ########################## [TYPES]
import { ISectionedLeftListWithRightDetailsProps } from '@ds-web/components/templates/sectioned/sectioned-left-list-with-right-details/types';

/**
 * This components just slices the view in two parts (25%, 75%): the left part is a list of component items,
 * while the right part is a generic child component that can be passed as a child.
 *
 * @interface ISectionedLeftListWithRightDetails
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const SectionedLeftListWithRightDetails: FC<ISectionedLeftListWithRightDetailsProps> =
  memo(
    ({
      leftList,
      leftListWrapperClassName,
      rightChildren,
      rightChildrenWrapperClassName,
    }) => {
      return (
        <div
          data-test="sectioned-left-list-with-right-details"
          className="w-full h-full flex flex-col sm:flex-row"
        >
          <section
            data-test="left-list-section"
            className={classnames(
              'w-full sm:w-1/2 md:w-1/3',
              '!overflow-y-auto',
              'flex flex-col justify-start items-start',
              'bg-[color:--shp-color-bg-highlight]',
              leftListWrapperClassName,
            )}
          >
            {leftList.map((item, i) =>
              item ? (
                <div
                  data-test="left-list-item-wrapper"
                  className="w-full flex justify-start items-start"
                  key={i}
                >
                  {item}
                </div>
              ) : (
                <Fragment key={i} />
              ),
            )}
          </section>
          <section
            data-test="right-body-section"
            className={classnames(
              'w-full sm:w-1/2 md:w-2/3 flex justify-start items-start bg-[color:--shp-color-bg]',
              rightChildrenWrapperClassName,
            )}
          >
            {rightChildren}
          </section>
        </div>
      );
    },
  );

SectionedLeftListWithRightDetails.displayName =
  'SectionedLeftListWithRightDetails_memoized';

import { FC } from 'react';

import { Dot } from '@ds-web/components';

import { IDotWithTitleProps } from '@ds-web/components/molecules/ui/dots/dot-with-title/types';

/**
 * Component to render a title with a colored dot
 * @param {string} subtitle - The subtitle to render
 * @param {Color | string} color - The color of the dot
 * @param {string} dataTestId - The data test id
 * @returns {ReactElement}
 *
 * @author Federico Mauri<federico.mauri@shippypro.com>
 */
export const DotWithTitle: FC<IDotWithTitleProps> = ({
  subtitle,
  color,
  dataTestId,
}) => {
  return (
    <div className={'flex'} data-test={dataTestId}>
      <Dot
        dotClassName="!relative !mt-[6px] !mb-0 !ml-[6px] !size-[10px]"
        color={color}
      />
      {subtitle}
    </div>
  );
};

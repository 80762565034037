/**
 * This function returns a promise used by the loadOptions selectProps of the Controlled selects
 * as callback to fetch and populates options list
 */
export const returnLoadOptionsPromise = <T>(
  search_term: string,
  promise: Promise<T>,
  minChars: number = 3,
): Promise<T> => {
  if (search_term.length >= minChars) {
    return promise;
  } else {
    // This is needed as the AsyncSelect component always show us the "loading" spinner
    // even if no request has been made (it takes a callback or a promise to execute, and show the spinner when the execution starts)
    return Promise.resolve([] as T);
  }
};

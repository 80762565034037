import { forwardRef, memo } from 'react';
import classnames from 'classnames';

import { Card, CardBody } from '@ds-web/components/atoms/ui/card';

import { ICardWrapperProps } from './types';

/**
 * A simple component that wraps a card over a centered content, with the additional ability to include a heading content.
 *
 * @interface IBulletTextProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const CardWrapper = memo(
  forwardRef<HTMLElement, ICardWrapperProps>(
    (
      {
        fullScreen = false,
        centered = true,
        middle = false,
        wrapperClassName = 'app-card',
        header,
        bodyClassName,
        cardClassName,
        children,
        dataTest,
      },
      ref,
    ) => {
      return (
        <Card
          className={classnames('!p-0', cardClassName, {
            'dynamic-height': fullScreen,
          })}
          innerRef={ref}
          data-test={dataTest}
        >
          {header}
          <CardBody
            className={classnames(bodyClassName, {
              'text-center m-auto justify-center': centered,
              'flex items-center': middle,
            })}
          >
            <div className={wrapperClassName}>{children}</div>
          </CardBody>
        </Card>
      );
    },
  ),
);

CardWrapper.displayName = 'CardWrapper_memoized';

// ########################## [REDUX]
import { useSelector } from 'react-redux';
import { selectCarriersErrorMessages } from '@web/features/ship/selectors';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';

// ########################## [TYPES]
import {
  CarrierErrorMessageSuggestionWithLinks,
  CarriersErrorMessages,
} from '@web/features/ship/types';
import { useCallback, useMemo } from 'react';

export const useGetErrorMessageSuggestion = () => {
  const carriersErrorMessages = useSelector(selectCarriersErrorMessages());

  const getErrorMessageSuggestion = useCallback(
    (
      t: TFunction<'translation', undefined>,
      message: string,
      carrierName: string | null,
      carriersErrorMessages?: CarriersErrorMessages,
    ): CarrierErrorMessageSuggestionWithLinks | null => {
      // No suggestion for not supported carriers
      if (
        !carrierName ||
        !carriersErrorMessages ||
        !carriersErrorMessages[carrierName]
      )
        return null;

      // Find the matching error message in the carrier list
      const carrierErrorsList = carriersErrorMessages[carrierName];
      const messageObj = carrierErrorsList
        ? carrierErrorsList.find(e =>
            message.match(
              new RegExp(e.message.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')),
            ),
          )
        : null;

      // No suggestion if we don't find a match
      if (!messageObj) return null;

      // Gets the suggestion's translations
      const suggestionTransObj = messageObj.suggestion;

      // This is a guard, in case we forgot/mispell the translation key :) -> no suggestion, should never happen
      /* istanbul ignore next */
      if (!suggestionTransObj) return null;

      return {
        description: t(suggestionTransObj.description, {
          carrier: carrierName,
        }),
        links: messageObj.links?.length > 0 ? messageObj.links : null,
        proposedAction: t(suggestionTransObj.proposed_action, {
          carrier: carrierName,
        }),
      };
    },
    [],
  );

  return useMemo(
    () => ({
      carriersErrorMessages,
      getErrorMessageSuggestion,
    }),
    [carriersErrorMessages, getErrorMessageSuggestion],
  );
};

import { OrderCategory } from '@web/types/order';
import { UserPreferencesDomain } from '@web/types/user/preferences';
import {
  OrdersNumber,
  RowDensity,
  UserConfiguredTabsResult as DeliverUserConfiguredTabsResult,
} from '@web/features/ship/types';
import { UserConfiguredViewsResult as AnalyticsUserConfiguredTabsResult } from '@shippypro/data/analytics/types';
import { DateRange } from '@web/types/filters';

import {
  AmountColumnCode,
  CustomerColumnCode,
  DocumentationColumnCode,
  ErrorMessageColumnCode,
  ErrorTypeColumnCode,
  NotesColumnCode,
  ProductAvatarsColumnCode,
  ShippedExternalIdColumnCode,
  ShippingDateColumnCode,
  StatusColumnCode,
  StoreStatusColumnCode,
  TagsColumnCode,
  ToShipDateColumnCode,
  ToShipExternalIdColumnCode,
  TrackingCodeColumnCode,
} from '@web/features/ship/hooks/tables/columns';

export const DEFAULT_DeliverViews: DeliverUserConfiguredTabsResult = {
  preferences: {
    user_id: '-1',
    domain: UserPreferencesDomain.DeliverViews,
    views_list: [
      {
        can_be_moved: false,
        can_create_custom_from_view: true,
        configured_filters: null,
        code: 'ready-to-ship',
        hidden: null,
        is_default_view: true,
        icon: 'IconSend',
        table_rows_density: RowDensity.M,
        table_orders_number: OrdersNumber.fivehundred,
        name: 'Ready to Ship',
        position: 1,
        starting_category: OrderCategory.toShip,
        can_be_hidden: false,
        configured_table_columns: [
          {
            code: ToShipExternalIdColumnCode,
            can_be_moved: false,
            position: 1,
            can_be_hidden: false,
            hidden: false,
          },
          {
            code: ProductAvatarsColumnCode,
            can_be_moved: true,
            position: 2,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: ToShipDateColumnCode,
            can_be_moved: true,
            position: 3,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: CustomerColumnCode,
            can_be_moved: true,
            position: 4,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: AmountColumnCode,
            can_be_moved: true,
            position: 5,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: TagsColumnCode,
            can_be_moved: true,
            position: 6,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: StoreStatusColumnCode,
            can_be_moved: true,
            position: 7,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: NotesColumnCode,
            can_be_moved: true,
            position: 8,
            can_be_hidden: true,
            hidden: true,
          },
        ],
      },
      {
        can_be_moved: false,
        can_create_custom_from_view: true,
        configured_filters: null,
        code: 'shipped',
        hidden: null,
        is_default_view: true,
        icon: 'IconTruck',
        table_rows_density: RowDensity.M,
        table_orders_number: OrdersNumber.fivehundred,
        name: 'Shipped',
        position: 2,
        starting_category: OrderCategory.shipped,
        can_be_hidden: false,
        configured_table_columns: [
          {
            code: ShippedExternalIdColumnCode,
            can_be_moved: false,
            position: 1,
            can_be_hidden: false,
            hidden: false,
          },
          {
            code: StatusColumnCode,
            can_be_moved: true,
            position: 2,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: ProductAvatarsColumnCode,
            can_be_moved: true,
            position: 3,
            can_be_hidden: true,
            hidden: true,
          },
          {
            code: TrackingCodeColumnCode,
            can_be_moved: true,
            position: 4,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: DocumentationColumnCode,
            can_be_moved: true,
            position: 5,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: ShippingDateColumnCode,
            can_be_moved: true,
            position: 6,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: CustomerColumnCode,
            can_be_moved: true,
            position: 7,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: AmountColumnCode,
            can_be_moved: true,
            position: 8,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: NotesColumnCode,
            can_be_moved: true,
            position: 9,
            can_be_hidden: true,
            hidden: true,
          },
        ],
      },
      {
        can_be_moved: false,
        can_create_custom_from_view: false,
        configured_filters: null,
        code: 'error',
        hidden: null,
        is_default_view: true,
        icon: 'IconAlertCircle',
        table_rows_density: RowDensity.M,
        table_orders_number: OrdersNumber.fivehundred,
        name: 'Error',
        position: 3,
        starting_category: OrderCategory.error,
        can_be_hidden: false,
        configured_table_columns: [
          {
            code: ShippedExternalIdColumnCode,
            can_be_moved: false,
            position: 1,
            can_be_hidden: false,
            hidden: false,
          },
          {
            code: ProductAvatarsColumnCode,
            can_be_moved: true,
            position: 2,
            can_be_hidden: true,
            hidden: true,
          },
          {
            code: ShippingDateColumnCode,
            can_be_moved: true,
            position: 3,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: ErrorTypeColumnCode,
            can_be_moved: true,
            position: 4,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: ErrorMessageColumnCode,
            can_be_moved: true,
            position: 5,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: CustomerColumnCode,
            can_be_moved: true,
            position: 6,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: NotesColumnCode,
            can_be_moved: true,
            position: 7,
            can_be_hidden: true,
            hidden: true,
          },
        ],
      },
      {
        can_be_moved: false,
        can_create_custom_from_view: false,
        configured_filters: null,
        code: 'archived',
        hidden: false,
        is_default_view: true,
        icon: 'IconArchive',
        table_rows_density: RowDensity.M,
        table_orders_number: OrdersNumber.fivehundred,
        name: 'Archived',
        position: 4,
        starting_category: OrderCategory.archived,
        can_be_hidden: true,
        configured_table_columns: [
          {
            code: ShippedExternalIdColumnCode,
            can_be_moved: false,
            position: 1,
            can_be_hidden: false,
            hidden: false,
          },
          {
            code: ShippingDateColumnCode,
            can_be_moved: true,
            position: 2,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: StatusColumnCode,
            can_be_moved: true,
            position: 3,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: ProductAvatarsColumnCode,
            can_be_moved: true,
            position: 4,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: DocumentationColumnCode,
            can_be_moved: true,
            position: 5,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: CustomerColumnCode,
            can_be_moved: true,
            position: 6,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: AmountColumnCode,
            can_be_moved: true,
            position: 7,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: NotesColumnCode,
            can_be_moved: true,
            position: 8,
            can_be_hidden: true,
            hidden: true,
          },
        ],
      },
      {
        can_be_moved: false,
        can_create_custom_from_view: false,
        configured_filters: null,
        code: 'deleted',
        hidden: false,
        is_default_view: true,
        icon: 'IconTrash',
        table_rows_density: RowDensity.M,
        table_orders_number: OrdersNumber.fivehundred,
        name: 'Deleted',
        position: 5,
        starting_category: OrderCategory.deleted,
        can_be_hidden: true,
        configured_table_columns: [
          {
            code: ShippedExternalIdColumnCode,
            can_be_moved: false,
            position: 1,
            can_be_hidden: false,
            hidden: false,
          },
          {
            code: ShippingDateColumnCode,
            can_be_moved: true,
            position: 2,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: StatusColumnCode,
            can_be_moved: true,
            position: 3,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: ProductAvatarsColumnCode,
            can_be_moved: true,
            position: 4,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: DocumentationColumnCode,
            can_be_moved: true,
            position: 5,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: CustomerColumnCode,
            can_be_moved: true,
            position: 6,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: AmountColumnCode,
            can_be_moved: true,
            position: 7,
            can_be_hidden: true,
            hidden: false,
          },
          {
            code: NotesColumnCode,
            can_be_moved: true,
            position: 8,
            can_be_hidden: true,
            hidden: true,
          },
        ],
      },
    ],
  },
};

export const DEFAULT_AnalyticsViews: AnalyticsUserConfiguredTabsResult = {
  preferences: {
    user_id: '-1',
    domain: UserPreferencesDomain.AnalyticsViews,
    views_list: [
      {
        can_be_moved: false,
        can_create_custom_from_view: true,
        configured_filters: {
          dateRange: DateRange.LastMonth,
          sender: undefined,
          recipient: undefined,
          carrier: undefined,
        },
        code: 'main',
        hidden: null,
        is_default_view: true,
        name: 'Main',
        position: 1,
        can_be_hidden: false,
      },
    ],
  },
};

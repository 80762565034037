import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useHotjar from 'react-use-hotjar';

import {
  isNullOrEmpty,
  isNullOrEmptyString,
} from '@shippypro/design-system-web/functions';

import { isProductionEnv } from '@web/utils/functions';

import { useGetUser } from './api/useGetUser';
import { useGetHotJarFields } from './api/useGetHotJarFields';
import { useGetUserConfiguredTabs } from '@web/features/ship/hooks/api/tabs/useGetUserConfiguredTabs';

// These routes should NOT activate the hotjar instance, as it muddies the tracker logics
const FORBIDDEN_ROUTES = ['/', '/redirect-check'];

/**
 * Hook that creates & manages the Hotjar tracking connection
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {void} Nothin'
 */
const useManageHotjar = () => {
  const { user } = useGetUser();
  const { pathname } = useLocation();

  const shouldInitHotjar = useMemo(
    () => isProductionEnv() && !FORBIDDEN_ROUTES.includes(pathname),
    [pathname],
  );

  // Initialising HotJar vars
  const { fields } = useGetHotJarFields();
  const { views_list: apiTabs, isLoading } = useGetUserConfiguredTabs();

  const { readyState, initHotjar, identifyHotjar, stateChange } = useHotjar();

  // Initialising the Hotjar instance
  if (!readyState && shouldInitHotjar) initHotjar(2369550, 6);

  useEffect(() => {
    // Ignoring the following block from code coverage, as it's not possible to test it in a test environment
    /* istanbul ignore if */
    if (shouldInitHotjar) {
      if (
        readyState &&
        !isNullOrEmpty(fields) &&
        !isNullOrEmpty(apiTabs) &&
        !isLoading &&
        !isNullOrEmptyString(user?.id?.toString())
      ) {
        // Passing the user data to Hotjar and triggering a state change on page switch
        stateChange(pathname);
        identifyHotjar(user!.id!.toString(), {
          ...fields,
          hasCustomTabs: apiTabs.some(tab => !tab.is_default_view),
        });
      }
    }
    // The 'pathname' is needed for Hotjar to recognise WHEN to initialise its snippet.
    // It HAS to re-render at every page due to a Hotjar bug found by their support staff in one of our convos.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldInitHotjar,
    fields,
    readyState,
    user?.id,
    pathname,
    isLoading,
    apiTabs,
  ]);
};

export default useManageHotjar;
